import axios from "axios";
import { getRefreshToken, isAccessTokenExpired, setAuthUser } from "./auth";
import { API_BASE_URL } from "./constants";
import Cookies from 'js-cookie';
import { useAuthStore } from "../store/auth";
import { useAlertStore } from "../store/alert";

const useAxios = () => {
    const accessToken = Cookies.get('access_token');
    const refreshToken = Cookies.get('refresh_token');

    const axiosInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
    });

    axiosInstance.interceptors.request.use(async (req) => {
        if (!isAccessTokenExpired(accessToken)) return req;

        try {
            
            const response = await getRefreshToken(refreshToken);
            // console.log(response);
            setAuthUser(response.access, response.refresh);
            req.headers.Authorization = `Bearer ${response.access}`;
            return req;
        } catch (error) {
            Cookies.remove('access_token');
            Cookies.remove('refresh_token');
            useAuthStore.getState().setUser(null);
            useAlertStore.setState({ visible: true })
            useAlertStore.setState({ error: "Сессия была завершена." })
            return req;
        }
    });

    return axiosInstance
};

export default useAxios;