import React from 'react'
import WebsiteNavbar from '../navbar/WebsiteNavbar'


const MinfinPage = () => { 
    return (
        <>
            <WebsiteNavbar />
            <p>Minfin page</p>
        </>
    )
}

export default MinfinPage