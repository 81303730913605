import { Container } from 'react-bootstrap';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";

import PrivateRoute from './layouts/PrivateRoute'
import MainWrapper from './layouts/MainWrapper';
import MainPage from './components/mainPage/MainPage';
import Calculator from './components/calculator/Calculator';
import LoginPage from './components/auth/LoginPage';
import LogoutPage from './components/auth/LogoutPage';
import { useAlertStore } from './store/alert';
import AlertFrom from './components/auth/AlertFrom';
import MinfinOnlyWrapper from './layouts/MinfinOnlyWrapper';
import MinfinPage from './components/minfin/MinfinPage';


function App() {
  const alertVisible = useAlertStore((state) => state.visible);
  return (
    <Container>
      <BrowserRouter>
        <MainWrapper>
        {alertVisible ? <AlertFrom /> : <></>}
            <Routes>
              <Route 
                path='/'
                element={
                  <PrivateRoute>
                    <MainPage />
                  </PrivateRoute>
                }/>
                <Route 
                path='/calculator'
                element={
                  <PrivateRoute>
                    <Calculator />
                  </PrivateRoute>
                }/>
                <Route 
                path='/minfin'
                element={
                  <PrivateRoute>
                    <MinfinOnlyWrapper>
                      <MinfinPage />
                    </MinfinOnlyWrapper>
                  </PrivateRoute>
                }/>
                <Route path='login' element={ <LoginPage /> } />
                <Route path='logout' element={ <LogoutPage /> } />
            </Routes>
        </MainWrapper>
      </BrowserRouter>
    </Container>
  )
}

export default App;
