import React, { useEffect, useState, useRef } from 'react'
import { Button, Dropdown, Form, Spinner, } from 'react-bootstrap'
import useAxios from '../../utils/useAxios'
import { useCalculatorStore } from '../../store/calculator';
import SearchInput from './SearchInput';
import moment from 'moment';
import showAlert from '../../utils/alert';


const InputForm = () => {
  const api = useAxios();
  const selectedBondData = useCalculatorStore((state) => state.getSelectedBondData);
  const isLoading = useCalculatorStore((state) => state.loading);
  const isLoadingRef = useRef(useCalculatorStore.getState().isLoading)
  const [isPriceLoad, setIsPriceLoad] = useState(false)

  const [formData, setFormData] = useState({
    estDate: '',
    rateDate: '',
    price: '',
    saveHistory: true,
  })

  useEffect(() => useCalculatorStore.subscribe(
    state => (isLoadingRef.current = state.isLoading)
  ), [])

  useEffect(() => {
    isLoadingRef.current();
  }, [isLoading]);

  const validateData = () => {
    let isValid = true
    let msg = ''

    if (!selectedBondData()?.secid) {
      msg = 'Облигация не выбрана'
      return [false, msg]
    }

    return [isValid, '']
  }

  api.interceptors.response.use(async (req) => {
    useCalculatorStore.getState().setLoading(false)
    return req
  });

  const handleChanges = (e) => {
    let { name, value } = e.target;
    if (e.target.name === "saveHistory") {
      name = e.target.name
      value = e.target.checked
    }

    setFormData({
        ...formData,
        [name]: value,
    });
  };

  const fetchData = async () => {
    const [isValid, msg] = validateData()

    if (!isValid) {
      showAlert(msg)
      return ;
    }
    useCalculatorStore.getState().setLoading(true)
    try {
      let queryParams = {
        bondRMFS: selectedBondData().gosreg,
        estDate: formData.estDate,
        lastKnownRuoniaDate: formData.rateDate,
        prices: formData.price,
      }
      if (formData.saveHistory) {
        queryParams = Object.assign({}, queryParams, {saveHistory: formData.saveHistory});
      }
    
      const response = await api.get('/calculator/dm/', { params: queryParams })
      const resultData = response.data.data[0]
      useCalculatorStore.setState({ calcResult: resultData })
    } catch (error) {
        useCalculatorStore.getState().setLoading(false)
        if (error.response.status === 400) {
          showAlert(error.response.data.data.error.description)
          
        } else {
          console.log('REQUIRE ALERT', error);
        }
    }
    
  }

  const getMarketData = async ({ bondSecid }) => {
    setIsPriceLoad(true)
    let queryParams = {
      bondSecid: bondSecid ? bondSecid : null,
    }
    try {
      const response = await api.get('/moex/bonds/marketdata/', { params: queryParams })
      const resultData = response.data.data[0]

      if (!resultData.WAPRICE) {
        setIsPriceLoad(false)
        showAlert('Текущая цена на бирже отсутствует. Возможно торги ещё не начались, попробуйте позднее.')
        return ;
      } else {
          const price = resultData.WAPRICE
          setFormData({
            ...formData,
            "price": price,
        });
        setIsPriceLoad(false)
      }
    } catch (error) {
      setIsPriceLoad(false)
      showAlert('Произошла ошибка при запросе API.')
      console.log('REQUIRE ALERT', error);
    }
  }

  const handleSetFrequencyDate = (e) => {
      e.preventDefault()
      const daysDelta = Number(e.target.getAttribute('days'))
      const name = e.target.getAttribute('nametarget')
      let today = new Date()
      setFormData({
        ...formData,
        [name]: moment(today.setDate(today.getDate() + daysDelta)).format('YYYY-MM-DD'),
    });
  }

  const handleSetBondPrice = (e) => {
    e.preventDefault()

    if (!selectedBondData()?.secid) {
      showAlert('Облигация не задана')
      return ;
    }

    const bondSecid = selectedBondData().secid
    getMarketData({ bondSecid })
  } 

  const handleCalc = (e) => {
    e.preventDefault()
    try {
      fetchData()
    } catch (error) {
        useCalculatorStore.getState().setLoading(false)
        console.log('REQUIRE ALERT', error);
    }
  };

  return (
      <Form>
          <Form.Group className='mb-3'>
              <SearchInput></SearchInput>
          </Form.Group>
          <Form.Group className='mb-3' controlId='estDate'>
              <Form.Label>Дата оценки</Form.Label>
              <div className='d-flex'>
                <Form.Control type='date' name="estDate" value={formData.estDate} onChange={handleChanges} required/>
                <Button type='button' className='ms-2 t-button-nowrap' size='sm' variant='secondary' nametarget="estDate" days={0} onClick={handleSetFrequencyDate}>T  </Button>
                <Button type='button' className='ms-2 t-button-nowrap' size='sm' variant='secondary' nametarget="estDate" days={1} onClick={handleSetFrequencyDate}>T+1</Button>
              </div>
          </Form.Group>
          <Form.Group className='mb-3' controlId='rateDate'>
              <Form.Label>Дата последней известной RUONIA</Form.Label>
              <div className='d-flex'>
                <Form.Control type='date' name="rateDate"  value={formData.rateDate} onChange={handleChanges} required />
                <Button type='button' className='ms-2 t-button-nowrap' size='sm' variant='secondary' nametarget="rateDate" days={-1} onClick={handleSetFrequencyDate}>T-1</Button>
                <Button type='button' className='ms-2 t-button-nowrap' size='sm' variant='secondary' nametarget="rateDate" days={-2} onClick={handleSetFrequencyDate}>T-2</Button>
              </div>

          </Form.Group>
          <Form.Group className='mb-3' controlId='price'>
              <Form.Label>Цена облигации</Form.Label>
              <div className='d-flex'>
                <Form.Control type='number' name="price" placeholder='0.0000' value={formData.price} onChange={handleChanges} required />
                <Button type='button' className='ms-2' size='sm' variant='secondary' style={{"whiteSpace": "nowrap"}} onClick={handleSetBondPrice} disabled={isPriceLoad}>
                  {isPriceLoad ? <Spinner as="span" animation='border' size='sm' role='status' aria-hidden="true"></Spinner> : ""} Рыночная цена
                </Button>
              </div>
          </Form.Group>
          <Form.Group className='mb-3' controlId='saveHistory'>
              <Form.Check 
                type='checkbox' 
                name="saveHistory" 
                label="Сохранить в историю запросов?" 
                checked={formData.saveHistory} 
                onChange={handleChanges} 
                required />
          </Form.Group>
          <Button disabled={isLoading} variant='primary' type='button' onClick={handleCalc}>{ isLoading ? "Загрузка...": "Расcчитать" }</Button>
      </Form>
  )

}

export default InputForm