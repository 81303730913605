import { create } from 'zustand';


const useAlertStore = create((set) => ({
    visible: false,
    error: null,

    setVisible: () => set((state) => ({ visible: state })),
    setError: () => set((state) => ({ error: state })),
    // getError: () => get().error
}))

export { useAlertStore };