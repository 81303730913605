import { Button, Modal } from "react-bootstrap"
import { useAlertStore } from "../../store/alert"


const AlertFrom = () => {
    const alertError = useAlertStore((state) => state.error);
    const visible = useAlertStore((state) => state.visible)

    const handleClose = () => {
        useAlertStore.setState({ visible: false })
        useAlertStore.setState({ error: null })
    }

    if (visible) {
        return (
            <Modal show={visible} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Уведомление</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {alertError}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="info" type="button" onClick={handleClose}>OK</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AlertFrom