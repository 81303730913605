import { useAuthStore } from '../store/auth';
import axios from './axios';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { useAlertStore } from '../store/alert';
import { useCalculatorStore } from '../store/calculator';


export const login = async (username, password) => {
    try {
        const { data, status } = await axios.post('user/token/', {
            username,
            password,
        });
        if (status === 200) {
            setAuthUser(data.access, data.refresh)
        }
        return { data, error: null }
    } catch (error) {
        return {
            data: null,
            error: error.response.data?.detail || 'Неверно заполнена форма входа.',
        };
    }
};

export const register = async (username, password, password2) => {
    try {
        const { data } = await axios.post('user/register/', {
            username,
            password,
            password2,
        });
        await login(username, password);
        return { data, error: null };
    } catch (error) {
        return {
            data: null,
            error: error.response.data || 'Неверно заполнена форма регистрации.',
        }
    }
}

export const logout = () => {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    useAuthStore.getState().setUser(null);
    useCalculatorStore.getState().setDefault()
};

export const setUser = async () => {
    const accessToken = Cookies.get('access_token')
    const refreshToken = Cookies.get('refresh_token')
    if (!accessToken || !refreshToken) {
        return;
    }
    if (isAccessTokenExpired(accessToken)) {
        const response = await getRefreshToken(refreshToken);
        setAuthUser(response.access, response.refresh);
    } else {
        setAuthUser(accessToken, refreshToken);
    }
};

export const setAuthUser = (access_token, refresh_token) => {
    Cookies.set('access_token', access_token, {
        expires: 1,
        secure: true,
    });

    Cookies.set('refresh_token', refresh_token, {
        expires: 7,
        secure: true,
    });

    const user = jwtDecode(access_token) ?? null;

    if (user) {
        useAuthStore.getState().setUser(user);
    }
    useAuthStore.getState().setLoading(false);
};

export const getRefreshToken = async () => {
    
    const refresh_token = Cookies.get('refresh_token');
    try {
        const response = await axios.post('user/token/refresh/', {
            refresh: refresh_token,
        })
        return response.data;
    } catch (error) {
        if (error?.response?.data?.code === "token_not_valid") {
            Cookies.remove('access_token');
            Cookies.remove('refresh_token');
            useAuthStore.getState().setUser(null);
            useAlertStore.setState({ visible: true })
            useAlertStore.setState({ error: "Сессия была завершена." })
        } else {
            useAlertStore.setState({ visible: true })
            useAlertStore.setState({ error: "Непредвиденная ошибка авторизации." })
            console.log(error);
        }
    };
};

export const isAccessTokenExpired = (accessToken) => {
    try {
        const decodedToken = jwtDecode(accessToken);
        return decodedToken.exp < Date.now() / 1000;
    } catch (err) {
        return true;
    }
}