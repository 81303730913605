import { useEffect } from "react"
import { logout } from "../../utils/auth"
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const LogoutPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        logout();
    }, []);

    const handleToLoginPage = (e) => {
        e.preventDefault()
        navigate('/login/');
    }

    return (
        <Container fluid className='vh-100 align-content-center'>
             <Row className="justify-content-center">
                <Col xs={10} md={4}>
                    <Card className="mb-5 px-5 py-3 bg-dark text-white">
                        <h1 className="m-3 text-center">Успешно вышли из системы</h1>
                        <Button className="btn btn-block" onClick={ handleToLoginPage }>На страницу входа</Button>
                    </Card>
                </Col>
             </Row>
        </Container>
    )
}


export default LogoutPage