import React, { useState } from 'react';
import { Pagination } from "react-bootstrap";

const Paginator = ({clsName, curr, setCurrPage, maxPage}) => {

    const pageChangeFunction = (p) => {
        if (p >= 1 && p <= maxPage) {
            setCurrPage(p)
        }
    };

    const showPageItemsFunction = () => {
        const data = [];
        const numPage = 5;
        if (maxPage <= numPage) {
            for (let i = 1; i <= maxPage; i++) {
                data.push(
                    <Pagination.Item
                        key={i}
                        active={i === curr}
                        onClick={() => pageChangeFunction(i)}
                    >
                        {i}
                    </Pagination.Item>
                );
            }
        } else {
            const leftside = curr - numPage / 2 > 1;
            const rightside = curr + numPage / 2 < maxPage;
            data.push(
                <Pagination.First
                    key="first"
                    onClick={() => pageChangeFunction(1)}
                />
            );
            data.push(
                <Pagination.Prev
                    key="prev"
                    onClick={() => pageChangeFunction(curr - 1)}
                />
            );
            if (leftside) {
                data.push(<Pagination.Ellipsis key="leftEllipsis" />);
            }
            const str = Math.max(1, Math.round(curr - numPage / 2));
            const end = Math.min(maxPage, Math.round(curr + numPage / 2));
            for (let i = str; i <= end; i++) {
                data.push(
                    <Pagination.Item
                        key={i}
                        active={i === curr}
                        onClick={() => pageChangeFunction(i)}
                    >
                        {i}
                    </Pagination.Item>
                );
            }
            if (rightside) {
                data.push(<Pagination.Ellipsis key="rightEllipsis" />);
            }
            data.push(
                <Pagination.Next
                    key="next"
                    onClick={() => pageChangeFunction(curr + 1)}
                />
            );
            data.push(
                <Pagination.Last
                    key="last"
                    onClick={() => pageChangeFunction(maxPage)}
                />
            );
        }
        return data;
    }
    return (
        <div>
           {maxPage > 1 ? <Pagination className={`${clsName} `}>{showPageItemsFunction()}</Pagination> : null}
        </div>
    );
}

export default Paginator