import { Button, Modal } from "react-bootstrap"



const ConfirmRejectModal = (props) => {

    const modalProps = props.modalProps
    const modalTitle = props.modalTitle
    const modalBody = props.modalBody

    return (
        <Modal
            {...modalProps}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{modalTitle}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {modalBody}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={props.onConfirm}>Подтвердить</Button>
                <Button variant="primary" onClick={props.onReject}>Отменить</Button>
            </Modal.Footer>

        </Modal>
    )
}

export default ConfirmRejectModal