import { Navigate } from "react-router-dom";
import { useAuthStore } from "../store/auth";
import { IsAdminOrModeratorOrMinifinStaff } from "../utils/permissions";



const MinfinOnlyWrapper = ({ children }) => {
    const role = useAuthStore((state) => state.role)();
    return IsAdminOrModeratorOrMinifinStaff(role) ? <>{children}</> : <Navigate to="/" />
};

export default MinfinOnlyWrapper;