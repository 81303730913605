import React, { useEffect, useState } from 'react'
import { Dropdown, Form, } from 'react-bootstrap'
import useAxios from "../../utils/useAxios";
import { useCalculatorStore } from '../../store/calculator';


const SearchInput = () => {
    const api = useAxios();
    const [searchList, setSearchList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedKey, setSelectedKey] = useState(0);

    const searchKeyName = "name"
    // const searchFiledName = "name"

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/moex/bonds/search/?searchKey=${searchValue}`)
                setSearchList(response.data.data)
            } catch (error) {
                
            }
        }
        fetchData()
    }, [searchValue])

    const CustomToggle = React.forwardRef(
        (props, ref) => (
          <a
            href=""
            ref={ref}
            onClick={e => {
              e.preventDefault();
              props.onClick(e);
            }}
            className='text-decoration-none'
            // data-bs-toggle="dropdown"
          >
            {props.children}
          </a>
        )
      );

      const CustomMenu = React.forwardRef((props, ref) => {
          return (
            <div
              ref={ref}
              style={props.style}
              className={props.className}
              aria-labelledby={props.labeledBy}
            >
              <Form.Control
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Начните вводить номер облигации..."
                onChange={e => setSearchValue(e.target.value)}
                value={searchValue}
                style={{'minWidth': '350px'}}
              />
              <ul className="list-unstyled">
                {React.Children.toArray(props.children)}
              </ul>
            </div>
          );
        }
      );

    const DropdownSelector = () => {
        
        const theChosenKey = () => {
          const chosenItem = searchList.find(f => f.id === selectedKey);
          const chosenValue = chosenItem ? chosenItem[searchKeyName] : ""
          useCalculatorStore.setState({ selectedBondData: chosenItem })
          return <Form.Control className='pe-none' value={chosenValue} placeholder="Выберите облигацию..." readOnly/>
        };

        const handleOnSelect = (e) => {
            setSelectedKey(Number(e))
        }
      
        return (
          <Dropdown onSelect={handleOnSelect}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {theChosenKey()}
            </Dropdown.Toggle>
      
            <Dropdown.Menu as={CustomMenu} style={{'maxHeight': '300px', 'width': '-webkit-fill-available', 'overflowY': 'scroll'}}>
              {searchList.map(el => {
                return (
                  <Dropdown.Item key={el.id} eventKey={el.id.toString()}>
                    {el[searchKeyName]}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        );
      };
    
    return DropdownSelector()
}

export default SearchInput