import React, { useState } from 'react'
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap'
import InputForm from './InputForm'
import OutputTable from './OutputTable'
import HistoryTable from './HistoryTable'
import WebsiteNavbar from '../navbar/WebsiteNavbar'
import useAxios from '../../utils/useAxios'
import ConfirmRejectModal from '../modals/ConfirmRejectModal'


const Calculator = () => {
    const api = useAxios();
    const [deleteHistoryModalShow, setDeleteHistoryModalShow] = useState(false)
    const [recordCounts, setRecordCounts] = useState(0)
    const [historyPage, setHistoryPage] = useState(1)

    const modalTitle = "Подтвердите действие"
    const modalBody = "Вы точно хотите очистить историю запросов? Восстановить данные будет невозможно!"
    const modalProps = {
        show: deleteHistoryModalShow,
        onHide: () => setDeleteHistoryModalShow(false),
    }

    const onConfirmDeleteHistory = () => {
        deleteHistoryData()
    }

    const onRejectDeleteHistory = () => {
        setDeleteHistoryModalShow(false)
    }

    const deleteHistoryData = async () => {
        try {
            const response = await api.delete('/calculator/dm/history/')

            if (response.status === 204) {
                setRecordCounts(0)
                setHistoryPage(1)
                setDeleteHistoryModalShow(false)
            } else {
                console.log('REQUIRE ALERT', response.data);
                setDeleteHistoryModalShow(false)
            }
        } catch (error) {
            console.log('REQUIRE ALERT', error);
            setDeleteHistoryModalShow(false)
        }
    }

    const fetchData = async ({ fileType }) => {
        try {
            if (fileType === 'excel') {
                const headers = {'Content-Type': 'blob'};
                const response = await api.get('/calculator/dm/history/download/excel/', {headers: headers, responseType: 'arraybuffer'})
                return response
            } else {
                const response = await api.get('/calculator/dm/history/download/csv/')
                return response
            }
        } catch (error) {
            console.log('REQUIRE ALERT', error);
        }
    }

    api.interceptors.response.use(async (req) => {
        if (req.config.method === "delete") {
            return req
        }
        const downloadUrl = window.URL.createObjectURL(new Blob([req.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const fileType = req.request.responseURL.split('/').slice(-2, -1)[0]
        if (fileType === 'excel') {
            link.setAttribute('download', 'file.xlsx');
        } else {
            link.setAttribute('download', 'file.csv');
        }
        document.body.appendChild(link);
        link.click();
        link.remove();
        return req
      });
    
    const handleClickHistoryBtn = (e) => {
        const fileType = e.target.name
        fetchData({ fileType })
    }

    return (
        <>
            <WebsiteNavbar />
            <Container>
                <ConfirmRejectModal 
                    modalTitle={modalTitle} 
                    modalBody={modalBody}
                    modalProps={modalProps}
                    onConfirm={onConfirmDeleteHistory}
                    onReject={onRejectDeleteHistory} 
                />
                <Row>
                    <Col>
                        <h3>Пользовательские параметры</h3>
                        <InputForm />
                    </Col>
                    <Col>
                        <h3>Результаты расчёта</h3>
                        <OutputTable />
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <h3>История запросов</h3>
                    <div className='d-flex mb-2' style={{"justifyContent": "right"}}>
                        <Button className='me-1' variant='danger' type='button' onClick={() => setDeleteHistoryModalShow(true)}>Очистить историю</Button>
                        <Dropdown>
                            <Dropdown.Toggle variant='success' id='dropdown-download'>Выгрузить историю</Dropdown.Toggle>
                            <Dropdown.Menu className='dropdown-menu-left'>
                                <Dropdown.Item name="excel" onClick={handleClickHistoryBtn}>Excel</Dropdown.Item>
                                <Dropdown.Item name="csv" onClick={handleClickHistoryBtn}>CSV</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Col>
                        <HistoryTable 
                            recordCounts={recordCounts} 
                            setRecordCounts={setRecordCounts}
                            historyPage={historyPage}
                            setHistoryPage={setHistoryPage}
                        />
                    </Col>
                </Row>
            </Container>
        </>

    )

}

export default Calculator