

export const IsAdminOrModeratorOrMinifinStaff = (userRole) => {
    const role_list = ['minfin_staff', 'moderator', 'admin']
    return role_list.includes(userRole)
}

export const IsAdminOrModerator = (userRole) => {
    const role_list = ['moderator', 'admin']
    return role_list.includes(userRole)
}