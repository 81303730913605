import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import useAxios from '../../utils/useAxios';
import { useCalculatorStore } from '../../store/calculator';
import Paginator from '../Pagination';


const HistoryTable = (props) => {
    const [tableData, setTableData] = useState({data: [], columns: []})
    const [maxHistoryPages, setMaxHistoryPages] = useState(1)
    const calcResult = useCalculatorStore((state) => state.calcResult);
    const api = useAxios();

    const fetchData = async () => {
        const getParams = {
            orderBy: "request_datetime",
            orderType: "-",
            page: props.historyPage,
        }
        try {
            const response = await api.get('/calculator/dm/history/', {params: getParams})
            return response
        } catch (error) {
            console.log(error);
        }
    }

    api.interceptors.response.use(async (req) => {
        setTableData({data: req.data.data, columns: req.data.columns})
        setMaxHistoryPages(req.data.max_pages)
        props.setRecordCounts(req.data.count)
        return req
      });
    
    useEffect(() => {
        fetchData()
    }, [calcResult, props.historyPage, props.recordCounts]);

    return (
        <>  
            <div className="col-lg-12">
            <div className='table-responsive'>
            <Table className='rounded-3 border-secondary table-hover' style={{"overflow": "hidden"}}>
                <thead>
                    <tr className='text-center'>
                        {Object.entries(tableData["columns"]).map(([key, value]) => {
                                return (
                                    <th className='align-middle' key={key}>{tableData["columns"][key]}</th>
                                )
                            })}
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(tableData["data"]).map(([key, value]) => {
                        return (
                            <tr key={key}>
                                {Object.entries(tableData["columns"]).map(([key2, value2]) => {
                                    let outputValue = value[key2]
                                    if (key2 === "dm") {
                                        outputValue = outputValue.toFixed(0)
                                    } else if (key2 === "ytm") {
                                        outputValue = outputValue.toFixed(2)
                                    } else if (key2 === "price") {
                                        outputValue = outputValue.toFixed(4)
                                    } else if (key2 === "request_datetime") {
                                        outputValue = new Date(outputValue).toLocaleString("ru-RU", {timeZone: "Europe/Moscow"})
                                    }
                                    return (
                                        <td key={key2} className='text-center align-middle'>{outputValue}</td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            </div>
            </div>
            <Paginator clsName="justify-content-center" curr={props.historyPage} setCurrPage={props.setHistoryPage} maxPage={maxHistoryPages} />
            
        </>
    )

}

export default HistoryTable