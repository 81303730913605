import { create } from 'zustand';


const useCalculatorStore = create((set, get) => ({
    selectedBondData: {},
    loading: false,
    calcResult: {},

    setSelectedBondData: () => set((state) => ({ selectedBondData: state })),
    getSelectedBondData: () => get().selectedBondData,

    setCalcResult: () => set((state) => ({ calcResult: state })),
    getCalcResult: () => get().calcResult,

    setLoading: (loading) => set({ loading }),
    isLoading: () => get().loading,

    setDefault: () => set({
        selectedBondData: {},
        loading: false,
        calcResult: {},
    })
}))

export { useCalculatorStore };