import React, { useEffect, useRef } from 'react'
import { Table, Spinner } from 'react-bootstrap'
import { useCalculatorStore } from '../../store/calculator';


const OutputTable = () => {
    const isLoading = useCalculatorStore((state) => state.loading);
    const calcResult = useCalculatorStore((state) => state.calcResult);
    const getCalcResultRef = useRef(useCalculatorStore.getState().getCalcResult);
    const defaultValue = "--/--"

    const columnsData = {
        bond_secid: "Код облигации",
        price: "Цена, %",
        dm: "Дисконтная маржа, бп",
        ytm: "Доходность, %",
        duration: "Дюрация, дней",
        estimation_date: "Оценка на дату",
        ai: "НКД, руб",
        last_known_rounia: "Дата RUONIA",
        last_ruonia_value: "Значение RUONIA, %",
    }

    useEffect(() => useCalculatorStore.subscribe(
        state => (getCalcResultRef.current = state.getCalcResult)
      ), []);

    useEffect(() => {
        getCalcResultRef.current()
      }, [calcResult]);

    const showSpinner = () => {
        return <Spinner size='sm' animation='border' variant='secondary' role='status'/>
    }

    return (
        <Table className='rounded-3 border-secondary table-hover' bordered style={{"overflow": "hidden"}}>
            <thead>
                <tr>
                    <th>Наименование показателя</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(columnsData).map(([key, value]) => {
                    let outputValue = calcResult[key]
                    
                    if (outputValue) {
                        if (key === "dm") {
                            outputValue = outputValue.toFixed(0)
                        } else if (key === "ytm") {
                            outputValue = outputValue.toFixed(2)
                        } else if (key === "price") {
                            outputValue = outputValue.toFixed(4)
                        }
                    }
                    return (
                        <tr key={key}>
                            <td>{columnsData[key]}</td>
                            <td className='text-center'>{isLoading ? showSpinner(): (outputValue || defaultValue)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )

}

export default OutputTable