import React from 'react'
import WebsiteNavbar from '../navbar/WebsiteNavbar'


const MainPage = () => { 
    return (
        <>
            <WebsiteNavbar />
            <p>Welcome to website!</p>
        </>
    )
}

export default MainPage