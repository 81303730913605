import React from 'react';
import { Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { Navbar } from 'react-bootstrap';
import { useAuthStore } from '../../store/auth';
import { IsAdminOrModerator, IsAdminOrModeratorOrMinifinStaff } from '../../utils/permissions';

const WebsiteNavbar = () => {
    const role = useAuthStore((state) => state.role)();
    const IsMinifinStaff = IsAdminOrModeratorOrMinifinStaff(role)
    const IsModerator = IsAdminOrModerator(role)

    return (
        <Navbar bg='dark' data-bs-theme="dark" className='mb-4'>
            <Container>
                <Navbar.Brand><NavLink className="nav-link" to="/">DataBase</NavLink></Navbar.Brand>
                <Nav className='me-auto'>
                    <NavLink className="nav-link" to="/calculator">Калькулятор</NavLink>
                    {IsMinifinStaff ? <NavLink className="nav-link" to="/minfin">Только для МФ</NavLink> : <></>}
                </Nav>
                <Nav className='me-right'>
                    <NavLink className="nav-link" to="/logout">Выйти</NavLink>
                </Nav>
            </Container>
        </Navbar>
    )

}

export default WebsiteNavbar