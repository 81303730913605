import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from '../../store/auth';
import { login } from '../../utils/auth';
import { useAlertStore } from '../../store/alert';


const LoginPage = () => { 
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn);


    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/')
        }
    }, []);

    const resetFrom = () => {
        setUsername('');
        setPassword('');
    };
    
    const handleLogin = async (e) => {
        e.preventDefault()
        const { error } = await login(username, password);
        if (error) {
            useAlertStore.setState({ visible: true })
            useAlertStore.setState({ error: error })
        } else {
            navigate('/');
            resetFrom();
        }
    };

    return (
        <Container fluid className='vh-100 align-content-center'>
            <Row className="justify-content-center">
                <Col xs={10} md={4}>
                    <Card className="mb-5 px-5 py-3 bg-dark text-white">
                        <h1 className="m-3 text-center">Вход</h1>
                        <Form onSubmit={ handleLogin }>
                            <Form.Group className="my-2">
                                <Form.Label>Логин</Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Введите логин..."
                                value={username}
                                name="login"
                                onChange = {(e) => setUsername(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>Пароль</Form.Label>
                                <Form.Control
                                type="password"
                                placeholder="Введите пароль..."
                                value={password}
                                name="password"
                                autoComplete="on"
                                onChange = {(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>
                            <div className="mt-3 text-center">
                                <Button className="btn btn-block" type='submit'>Войти</Button>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginPage